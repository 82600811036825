<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Delivery Orders"
              url="/pages/sales-tools/delivery-order/manage"
              :data-source="dataSource"
              :dataColumns="dataColumns"
              @on-delete="(v) => onDelete(v)"
              @onSelectionChanged="(v) => (selectedData = v)"
            >
              <template #action-buttons>
                <DxButton icon="message" hint="Send" :onClick="onSendEmail" />
                <DxButton icon="pdffile" hint="Print" :onClick="onPrintPDF" />
              </template>

              <template #columns>
                <DxColumn data-field="orderno" caption="Order No." />
                <DxColumn
                  :group-index="0"
                  data-field="company"
                  caption="Customer"
                />
                <DxColumn data-field="display_date" caption="Order Date" />
                <!-- <DxColumn data-field="employee_name" caption="Sales Person" /> -->

                <DxColumn
                  summary-type="sum"
                  value-format="currency"
                  data-field="total"
                />
              </template>

              <template #summaries>
                <DxSummary>
                  <DxGroupItem
                    column="id"
                    summary-type="count"
                    display-format="{0} orders"
                  />
                  <DxGroupItem
                    :show-in-group-footer="false"
                    :align-by-column="true"
                    column="total"
                    summary-type="max"
                    value-format="currency"
                  />
                  <DxGroupItem
                    :show-in-group-footer="true"
                    column="total"
                    summary-type="sum"
                    value-format="currency"
                    display-format="Total: {0}"
                  />
                </DxSummary>

                <DxSortByGroupSummaryInfo summary-item="count" />
              </template>
            </DxGrid>

            <div class="" v-if="selectedData.length > 0">
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="primary"
                size="sm"
                @click="actionStatus = 'print_batch'"
              >
                Print
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="success"
                size="sm"
                @click="actionStatus = 'confirm'"
              >
                Confirm
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="info"
                size="sm"
                @click="actionStatus = 'reopen'"
              >
                Reopen
              </b-button>
              <b-button
                v-b-modal.modal-center
                class="mr-1"
                variant="danger"
                size="sm"
                @click="actionStatus = 'void'"
              >
                Void
              </b-button>

              <Modal @onChange="(v) => handleEvent(actionStatus, {})" />
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import rfqApi from '@api/rfq_headers';
import employeeApi from '@api/employees';
import { queryParameters } from '@/schema';

const update = (data) => {
  rfqApi
    .update(data)
    .then(() => {
      //
    })
    .catch(() => {
      //
    })
    .finally(() => {
      //
    });
};

export default {
  name: 'DeliveryOrderPage',
  components: {},
  data: () => ({
    columns: [],
    dataSource: [],
    dataColumns: [
      { dataField: 'orderno', caption: 'Order No.' },
      { dataField: 'company', caption: 'Customer', groupIndex: 0 },
      { dataField: 'display_date', caption: 'Order Date' },
      { dataField: 'employee_id', caption: 'Sales Person' },
      { dataField: 'total', caption: 'Total', valueFormat: 'currency' },
    ],
    selectedData: [],
    actionStatus: '',
    employeeItems: [],
  }),
  mounted() {
    this.loadEmployees();
    this.loadRfq();
  },
  methods: {
    loadRfq() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      rfqApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            data.forEach((e) => {
              const user = this.employeeItems.find((x) => {
                return x.id == e.employee_id;
              });

              if (user) {
                e.employee_name = user.fullname;
              }
            });

            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeeApi
        .list(params)
        .then(({ data }) => {
          this.employeeItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      this.$nextTick(() => {
        if (action == 'confirm') {
          this.selectedData.forEach((data) => {
            data.status = 'C';
            update(data);
          });
        }

        if (action == 'reopen') {
          this.selectedData.forEach((data) => {
            data.status = 'R';
            update(data);
          });
        }

        if (action == 'void') {
          this.selectedData.forEach((data) => {
            data.status = 'V';
            update(data);
          });
        }

        if (action == 'print_batch') {
          //
        }
      });
    },

    onDelete(event) {
      rfqApi
        .delete(event)
        .then(() => {
          this.loadRfq();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSendEmail(event) {
      console.log(event);
    },
    onPrintPDF(event) {
      const data = event?.row?.data;

      if (data) {
        rfqApi
          .generatepdf(data.id)
          .then((r) => {
            const body = Object.values(r).join('');
            this.openPdf(body, 'preview', data.orderno);
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
  },
};
</script>

<style></style>
